.Mui-disabled {
  color: #003031;
  font-weight: 500;
  font-size: 0.8rem;
}
.Mui-completed {
  color: green !important;
  font-weight: 500;
  font-size: 0.8rem;
}

.Mui-active {
  color: orange !important;
  font-weight: bold;
  font-size: 0.8rem;
  background-color: #297f99;
  padding: 8px;
  /* border-radius: 1px; */
  border-bottom: 4px solid rgb(3, 29, 29);
}
