/* Custom CSS for TreeView */

.tree-node {
    margin-left: 10px;
    border-left: 1px solid #ccc;
    padding-left: 10px;
  }
  
  .node-header {
    cursor: pointer;
    padding: 5px;
  }
  
  .child-nodes {
    margin-left: 20px;
    margin-top: 5px;
  }
  